import state from "./livePlanningState";
import mutations from "./livePlanningMutations";
import getters from "./livePlanningGetters";
import actions from "./livePlanningActions";

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
