import { io } from "socket.io-client";
import $store from "../store";
import $router from "../router";
import { AuthService } from "@/services/auth";
import { PrivateMessagesService } from "@/services/privateMessages.service";

import {
  SOCKET_BASE_URL,
  CONNECTED,
  SOCKET_ERROR,
  PRIVATE_MESSAGES_RECEIVED,
  DISCONNECTED,
} from "../.env";

export class PrivateMessageSocketInstance {
  constructor() {
    this.socket = null;
  }

  connectToIo() {
    this.socket = io(SOCKET_BASE_URL, {
      query: {
        token: localStorage.getItem("accessToken"),
      },
      reconnectionDelay: 1000 * 10,
      transports: ["websocket"],
    });
    this.setEventsListeners();
    return this.socket;
  }

  setEventsListeners() {
    //try {

    this.socket.on(CONNECTED, () => {
      console.log(`PMS : ${this.socket.id} connect succesfully`);
    });

    this.socket.io.on("reconnect", () => {
      // ...
      console.log(`PMS : ${this.socket.id} reconnect succesfully`);
      PrivateMessagesService.connectToIo();
    });

    this.socket.io.on("error", (error) => {
      console.log(
        `PMS : ${this.socket.id} connection failed du to : `,
        error.message
      );
    });

    this.socket.io.on("reconnect_attempt", (attempt) => {
      console.log(`PMS : ${this.socket.id} reconnect attempt `, attempt);
    });

    this.socket.io.on("reconnect_failed", () => {
      // ...
      console.log(`PMS : ${this.socket.id} reconnect_failed `);
    });

    this.socket.io.on("connect_error", (err) => {
      console.log(
        `PMS : ${this.socket.id} connect_error due to ${err.message}`
      );
    });

    this.socket.on("connect_error", async (err) => {
      console.log(
        `PMS : ${this.socket.id} connect_error due to ${err.message}`
      );
      const $currentUser = localStorage.getItem("currentUser");

      if (
        err.message == "PLEASE_AUTHENTICATE" &&
        $currentUser !== null &&
        $currentUser.uuid !== "undefined"
      ) {
        const access_token = await AuthService.refreshTokens();
        this.socket.io.opts.query.token =
          access_token?.data?.tokens?.access?.token.token;

        this.socket.connect();
      }
    });

    this.socket.on("disconnect", (reasons) => {
      console.log(`PMS  disconnect`, reasons); // false
    });

    /**
     * Listening for incomming private message
     */
    this.socket.on(PRIVATE_MESSAGES_RECEIVED, (data) => {
      if (Array.isArray(data) && data.length > 0) {
        const msg = data[0];

        const currenUser = JSON.parse(localStorage.getItem("currentUser"));

        const newMessageToBeAppend = {
          uuid: msg.uuid,
          content: msg.content,
          sender: msg.sender,
          createdAt: new Date(msg.createdAt).toISOString(),
          updatedAt: new Date(msg.updatedAt).toISOString(),
          deletedAt: null,
          SenderUuid: msg.SenderUuid,
          ReceiverUuid: currenUser ? currenUser.uuid : "",
        };

        if (Object.is($router.currentRoute.name, "private-messaging")) {
          $store.dispatch("privateMessaging/appendNewIncommingMessage", {
            newMessageToBeAppend,
          });
        }
      }
    });

    /**
     * Listening to disconnection hook
     */
    this.socket.on(DISCONNECTED, () => {
      console.log(DISCONNECTED, ": disconnect fril socket");
    });

    /**
     * Listening for socket errors
     */
    this.socket.on(SOCKET_ERROR, async (data) => {
      const $currentUser = localStorage.getItem("currentUser");

      if (
        data.statusCode == 401 &&
        $currentUser != null &&
        $currentUser.uuid !== "undefined"
      ) {
        const access_token = await AuthService.refreshTokens();
        this.socket.io.opts.query.token =
          access_token?.data?.tokens?.access?.token.token;

        this.socket.connect();
      }
    });
  }
}
