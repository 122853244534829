export default {
  // data
  getAllTeachersProfilesList: (state) => state.allTeachersProfilesList,
  getAllStudentsProfilesList: (state) => state.allStudentsProfilesList,
  getAllAdminsProfilesList: (state) => state.allAdminsProfilesList,
  getAllModuleDelationRequest: (state) => state.allModuleDelationRequest,
  getCurrentTeacherModules: (state) => state.currentTeacherModules,
  getTeacherProfilesTotalCount: (state) => state.teacherProfilesTotalCount,
  getStudentProfilesTotalCount: (state) => state.studentProfilesTotalCount,
  getAdminProfilesTotalCount: (state) => state.adminProfilesTotalCount,
  getCurrentTeacherModulesTotalCount: (state) =>
    state.currentTeacherModulesTotalCount,
  getTeacherProfilesCurrentOffset: (state) =>
    state.teacherProfilesCurrentOffset,
  getStudentProfilesCurrentOffset: (state) =>
    state.studentProfilesCurrentOffset,
  getAdminProfilesCurrentOffset: (state) => state.adminProfilesCurrentOffset,
  getCurrentTeacherModulesOffset: (state) => state.currentTeacherModulesOffset,

  // data's loaders state getters
  areAllTeachersProfilesListLoading: (state) =>
    state.areAllTeachersProfilesListLoading,
  areAllStudentsProfilesListLoading: (state) =>
    state.areAllStudentsProfilesListLoading,
  areAllAdminsProfilesListLoading: (state) =>
    state.areAllAdminsProfilesListLoading,
  areCurrentTeacherModuleLoading: (state) =>
    state.areCurrentTeacherModuleLoading,
  areRegisteredStudentsLoading: (state) => state.areRegisteredStudentsLoading,
};
