import { ForumSocketInstance } from "../SocketsInstances/ForumSocketInstance";

import $store from "../store";
import {
  MODULE_FORUM_SUBSCRIBE,
  OLD_MODULE_FORUM_CONVERSATION,
  SEND_MODULE_FORUM_MESSAGE,
  ON_SUBSCRIBE_TO_PRINCIPAL_SOCKET_SEND,
} from "../.env";

//import {EventBus} from '@/plugins/globalEventBus';

export class ForumsService {
  static socket = null;

  static connectToIo(fromLogin = false) {
    const socketInstance = new ForumSocketInstance();

    if (localStorage.getItem("accessToken") !== null) {
      ForumsService.socket = socketInstance.connectToIo();
    }

    //ForumsService.privateMessagingSubscribing();
  }

  static moduleForumSubscribing({ ModuleUuid }) {
    this.socket.emit(
      MODULE_FORUM_SUBSCRIBE,
      { moduleUuid: ModuleUuid },
      (response) => {
        this.retrieveOldForumsMessages({ ModuleUuid });
      }
    );
  }

  static retrieveOldForumsMessages({
    ModuleUuid,
    now = new Date(),
    loadMore = false,
    size = 100,
  }) {
    this.socket.emit(
      OLD_MODULE_FORUM_CONVERSATION,
      {
        body: {
          moduleUuid: ModuleUuid,
          from: now.toISOString(),
          size: size,
        },
      },
      (response) => {
        const responseBody = response.body.sort(
          (objA, objB) => new Date(objA.createdAt) - new Date(objB.createdAt)
        );

        if (loadMore == true) {
          $store.dispatch("learningModuleForum/appendNewOldedMessagesLoaded", {
            responseBody,
          });
          $store.commit(
            "learningModuleForum/SET_ARE_APPEND_NEW_OLD_MESSAGE_LOADED_FORUM_CONVERSATIONS_LOADING",
            false
          );
        } else {
          $store.commit(
            "learningModuleForum/SET_FORUM_CONVERSATIONS",
            responseBody
          );
        }
      }
    );
  }

  static sendMessage({ ModuleUuid, Message }) {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const newMessageToBeAppend = {
      uuid: new Date().toISOString(),
      content: Message,
      sender: `${currentUser.ProfileIdentity.firstname} ${currentUser.ProfileIdentity.lastname}`,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      deletedAt: null,
      ProfileUuid: currentUser.uuid,
      ModuleUuid: ModuleUuid,
      ongoing: true,
      sendInThisSession: true,
    };

    $store.dispatch("learningModuleForum/appendNewMessage", {
      newMessageToBeAppend,
    });

    this.socket.emit(
      SEND_MODULE_FORUM_MESSAGE,
      {
        body: {
          moduleUuid: ModuleUuid,
          message: Message,
        },
      },
      (response) => {
        try {
          $store.dispatch("learningModuleForum/successFullSendMessage", {
            newMessageToBeAppend,
          });
        } catch (e) {
          console.log(e);
        }
      }
    );
  }

  static disconnect() {
    if (this.socket) {
      this.socket.disconnect(true);
    }
  }
}
