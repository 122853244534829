/* eslint-disable no-unused-vars */
import { AdminService } from "@/services/admin.service";
import { ErrorWrapper } from "@/services/util";

export default {
  async getTeacherModulesAction(
    { commit },
    teacherUuid,
    offset = 0,
    size = 50
  ) {
    try {
      const response = await AdminService.getTeacherModules(
        teacherUuid,
        offset
      );
      return response.data;
    } catch (error) {
      throw new ErrorWrapper(error, error?.message);
    }
  },

  async getModulesRegisteredStudentsAction(
    { commit },
    moduleUuid,
    offset = 0,
    size = 50
  ) {
    try {
      const response = await AdminService.getModulesRegisteredStudents(
        moduleUuid,
        offset
      );
      return response.data;
    } catch (error) {
      throw new ErrorWrapper(error, error?.message);
    }
  },
  async getAllTeachersProfilesAction({ commit }, offset = 0, size = 50) {
    try {
      const response = await AdminService.getAllTeachersProfiles(offset, size);
      return response.data;
    } catch (error) {
      throw new ErrorWrapper(error, error?.message);
    }
  },

  async getAllStudentsProfilesAction({ commit }, offset = 0, size = 50) {
    try {
      const response = await AdminService.getAllStudentsProfiles(offset, size);
      return response.data;
    } catch (error) {
      throw new ErrorWrapper(error, error?.message);
    }
  },

  async getAllAdminsProfilesAction({ commit }, offset = 0, size = 50) {
    try {
      const response = await AdminService.getAllAdminsProfiles(offset, size);
      return response?.data;
    } catch (error) {
      throw new ErrorWrapper(error, error?.message);
    }
  },

  async getAllModuleDelationRequestAction({ commit }) {
    try {
      const response = await AdminService.getAllModuleDeletionRequest();
      commit("SET_ALL_MODULE_DELATION_REQUEST", response.data);
      return response.data;
    } catch (error) {
      throw new ErrorWrapper(error, error?.message);
    }
  },

  async updateAdminProfileAction({ commit }, { data }) {
    try {
      const response = await AdminService.updateLearningModule({
        queryBody: data,
      });
      return response.data;
    } catch (error) {
      throw new ErrorWrapper(error, error?.message);
    }
  },
};
