import { io } from "socket.io-client";
import $store from "../store";
import $router from "../router";
import { EventBus } from "@/plugins/globalEventBus";
import { AuthService } from "@/services/auth";

import {
  SOCKET_BASE_URL,
  CONNECTED,
  SOCKET_ERROR,
  RECEIVE_MODULE_FORUM_MESSAGE,
  DISCONNECTED,
} from "../.env";

export class ForumSocketInstance {
  constructor() {
    this.socket = null;
  }

  connectToIo() {
    this.socket = io(SOCKET_BASE_URL, {
      query: {
        token: localStorage.getItem("accessToken"),
      },
      reconnectionDelay: 1000 * 10,
      transports: ["websocket"],
    });
    this.setEventsListeners();
    return this.socket;
  }

  setEventsListeners() {
    try {
      this.socket.on(CONNECTED, (data) => {
        console.log(`FS : ${this.socket.id} connect succesfully`);
      });

      this.socket.on("error", (error) => {
        console.log(error);
      });
      this.socket.io.on("connect_error", (err) => {
        console.log(
          `FS : ${this.socket.id} connect_error due to ${err.message}`
        );
      });

      this.socket.on("connect_error", async (err) => {
        console.log(
          `FS : ${this.socket.id} connect_error due to ${err.message}`
        );
        const $currentUser = localStorage.getItem("currentUser");

        if (
          err.message == "PLEASE_AUTHENTICATE" &&
          $currentUser !== null &&
          $currentUser.uuid !== "undefined"
        ) {
          const access_token = await AuthService.refreshTokens();
          this.socket.io.opts.query.token =
            access_token?.data?.tokens?.access?.token.token;

          this.socket.connect();
        }
      });

      this.socket.io.on("reconnect_attempt", (attempt) => {
        // ...
        console.log(`FS : ${this.socket.id} reconnect attempt`, attempt);
      });

      this.socket.io.on("reconnect", () => {
        // ...
        console.log("FS reconnect succesfully");
      });

      this.socket.io.on("reconnect_failed", () => {
        // ...
        console.log(`FS : ${this.socket.id} reconnect_failed `);
      });
      this.socket.on("disconnect", (reasons) => {
        console.log(`FS disconnect due to  disconnect`, reasons); // false
      });

      /**
       * Listening for incoming new forum messages;
       */
      this.socket.on(RECEIVE_MODULE_FORUM_MESSAGE, (data) => {
        const newMessageToBeAppend = {
          uuid: data.uuid,
          content: data.content,
          sender: data.sender,
          createdAt: new Date(data.createdAt).toISOString(),
          updatedAt: new Date(data.updatedAt).toISOString(),
          deletedAt: null,
          ProfileUuid: data.ProfileUuid,
          ModuleUuid: data.ModuleUuid,
        };
        $store.dispatch("learningModuleForum/appendNewMessage", {
          newMessageToBeAppend,
        });
      });

      /**
       * Listening to disconnection hook
       */
      this.socket.on(DISCONNECTED, (data) => {
        console.log(DISCONNECTED, ": disconnect fril socket");
      });

      /**
       * Listening for socket errors
       */
      this.socket.on(SOCKET_ERROR, async (data) => {
        const $currentUser = localStorage.getItem("currentUser");

        if (
          data.statusCode == 401 &&
          $currentUser != null &&
          $currentUser.uuid !== "undefined"
        ) {
          const access_token = await AuthService.refreshTokens();
          this.socket.io.opts.query.token =
            access_token?.data?.tokens?.access?.token.token;
          this.socket.connect();
        }
      });
    } catch (e) {
      console.log(e);
    }
  }
}
