/* eslint-disable no-unused-vars */
import { LearningModuleService } from "@/services/learning-module.service";
import { ErrorWrapper } from "../../../services/util";

export default {
  async createLearningModuleAction({ commit }, { data }) {
    try {
      const response = await LearningModuleService.createLearningModule({
        queryBody: data,
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  async getAllLearningModuleAction({ commit }) {
    try {
      const response = await LearningModuleService.getAllLearningModules();
      commit("SET_ALL_LEARNING_MODULE_LIST", response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  async getSingleLearningModuleAction({ commit }, moduleUuid) {
    try {
      const response = await LearningModuleService.getSingleLearningModule(
        moduleUuid
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  async updateALearningModuleAction({ commit }, { moduleUuid, data }) {
    try {
      const response = await LearningModuleService.updateLearningModule({
        learningModuleUuid: moduleUuid,
        queryBody: data,
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  async getLearningModulesByTeacherCodeAction({ commit }, teacherCode) {
    try {
      const response =
        await LearningModuleService.getLearningModulesByTeacherCode(
          teacherCode
        );

      commit("SET_TEACHER_MODULES_RETRIEVED_BY_TEACHER_CODE", response.data);
      return response.data;
    } catch (error) {
      throw new ErrorWrapper(error, error.message);
    }
  },

  async getConnectedTeacherLearningModulesAction({ commit }) {
    try {
      const response =
        await LearningModuleService.getConnectedTeacherLearningModules();
      commit("SET_CONNECTED_TEACHER_MODULES", response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  async getConnectedStudentLearningModulesAction({ commit }) {
    try {
      const response =
        await LearningModuleService.getConnectedStudentLearningModules();
      commit("SET_CONNECTED_STUDENT_MODULES", response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  async getStudentsByLearningModuleAction({ commit }, { queryBody }) {
    try {
      const response = await LearningModuleService.getStudentsByLearningModule({
        queryBody: queryBody,
      });
      commit("SET_STUDENTS_BY_LEARNING_MODULE", response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
};
