import { PrivateMessageSocketInstance } from "../SocketsInstances/PrivateMessageSocketInstance";
import $store from "../store";
import {
  PRIVATE_MESSAGES_SUBSCRIBE,
  PRIVATE_MESSAGES_OLD_CONVERSATION,
  SEND_PRIVATE_MESSAGE,
} from "../.env";

//import {EventBus} from '@/plugins/globalEventBus';

export class PrivateMessagesService {
  static socket = null;

  static connectToIo() {
    const socketInstance = new PrivateMessageSocketInstance();
    if (localStorage.getItem("accessToken") !== null) {
      PrivateMessagesService.socket = socketInstance.connectToIo();

      PrivateMessagesService.privateMessagingSubscribing();
    }
  }

  static privateMessagingSubscribing() {
    this.socket.emit(PRIVATE_MESSAGES_SUBSCRIBE, {}, (response) => {
      const currentUser = JSON.parse(localStorage.getItem("currentUser"));
      let responseBodyWithoutDuplication = [];

      if (currentUser.teacherStatus === false) {
        response.body.filter((el) => {
          var i = responseBodyWithoutDuplication.findIndex((x) => {
            return x.Module?.Creator?.uuid === el.Module?.Creator?.uuid;
          });
          if (i <= -1) {
            //console.log(el);
            responseBodyWithoutDuplication.push(el);
          }
          return null;
        });
      } else {
        response.body
          .flatMap((z) => {
            return z;
          })
          .filter((el) => {
            var i = responseBodyWithoutDuplication.findIndex((x) => {
              return x.Profile?.uuid === el.Profile?.uuid;
            });
            if (i <= -1) {
              //console.log(el);
              responseBodyWithoutDuplication.push(el);
            }
            return null;
          });
      }
      $store.commit(
        "privateMessaging/SET_PRIVATE_CONVERSATIONS_HISTORY",
        responseBodyWithoutDuplication
      );

      //this.listeningToIncommingPrivateMessage();
    });
  }

  static retrieveOldPrivateMessages({
    receiverUuid,
    now = new Date(),
    loadMore = false,
    size = 100,
  }) {
    this.socket.emit(
      PRIVATE_MESSAGES_OLD_CONVERSATION,
      {
        body: {
          receiverUuid: receiverUuid,
          from: now.toISOString(),
          size: size,
        },
      },
      (response) => {
        const responseBody = response.body.sort(
          (objA, objB) => new Date(objA.createdAt) - new Date(objB.createdAt)
        );
        //console.log(responseBody);
        if (loadMore == true) {
          $store.dispatch("privateMessaging/appendNewOldedMessagesLoaded", {
            responseBody,
          });
          $store.commit(
            "privateMessaging/SET_ARE_APPEND_NEW_OLD_PRIVATE_MESSAGE_CONVERSTATION_LOADED_LOADING",
            false
          );
        } else {
          $store.commit(
            "privateMessaging/SET_PRIVATE_CONVERSATIONS",
            responseBody
          );
          $store.commit(
            "privateMessaging/SET_UPDATE_SPECIQ_PERSON_IN_PRIVATE_CONVERSATIONS_HISTORY_LAST_MESSAGE",
            responseBody[responseBody.length - 1]
          );
        }
      }
    );
  }

  static sendPrivateMessage({
    ReceiverProfileUuid,
    ReceiverProfileIdentityUuid,
    Message,
  }) {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const newMessageToBeAppend = {
      uuid: new Date().toISOString(),
      content: Message,
      sender: `${currentUser.ProfileIdentity.firstname} ${currentUser.ProfileIdentity.lastname}`,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      deletedAt: null,
      SenderUuid: currentUser.uuid,
      ReceiverUuid: ReceiverProfileUuid,
      ongoing: true,
      sendInThisSession: true,
    };

    $store.dispatch("privateMessaging/appendNewMessage", {
      newMessageToBeAppend,
    });

    this.socket.emit(
      SEND_PRIVATE_MESSAGE,
      {
        body: {
          receiverProfileUuid: ReceiverProfileUuid,
          receiverProfileIdentityUuid: ReceiverProfileIdentityUuid,
          message: Message,
        },
      },
      (response) => {
        try {
          $store.dispatch("privateMessaging/successFullSendPrivateMessage", {
            newMessageToBeAppend,
          });
        } catch (e) {
          console.log(e);
        }
      }
    );
  }

  static disconnect() {
    if (this.socket) {
      this.socket.disconnect(true);
    }
  }
}
