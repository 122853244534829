export default {
  computed: {
    $currentUser() {
      let user = this.$store.state.user.currentUser;
      if (user.uuid) {
        return user;
      } else {
        user = JSON.parse(localStorage.getItem("currentUser")) || "";
      }
      return user;
    },
  },
};
