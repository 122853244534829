/* eslint-disable no-unused-vars */
import { PrivateMessagesService } from "@/services/privateMessages.service";

export default {
  privateMessagingSubscribingAction({ commit }) {
    PrivateMessagesService.privateMessagingSubscribing();
  },

  privateMessagingLoadMessageAction(
    { commit },
    { receiverUuid, fromDate = new Date(), loadMore = false }
  ) {
    PrivateMessagesService.retrieveOldPrivateMessages({
      receiverUuid: receiverUuid,
      now: fromDate,
      loadMore: loadMore,
    });
  },

  sendMessageAction(
    { commit },
    { receiverUuid, receiverProfileIdentityUuid, Message }
  ) {
    PrivateMessagesService.sendPrivateMessage({
      ReceiverProfileUuid: receiverUuid,
      ReceiverProfileIdentityUuid: receiverProfileIdentityUuid,
      Message: Message,
    });
  },

  appendNewMessage({ commit }, newMessage) {
    commit("SET_APPEND_NEW_PRIVATE_MESSAGE", newMessage.newMessageToBeAppend);
    commit(
      "SET_UPDATE_SPECIQ_PERSON_IN_PRIVATE_CONVERSATIONS_HISTORY_LAST_MESSAGE",
      newMessage.newMessageToBeAppend
    );
  },

  appendNewIncommingMessage({ commit }, newMessage) {
    commit(
      "SET_APPEND_NEW_INCOMING_PRIVATE_MESSAGE",
      newMessage.newMessageToBeAppend
    );
    commit(
      "SET_UPDATE_SPECIQ_PERSON_IN_PRIVATE_CONVERSATIONS_HISTORY_LAST_MESSAGE",
      newMessage.newMessageToBeAppend
    );
  },

  appendNewOldedMessagesLoaded({ commit }, newOldMessagesLoaded) {
    commit(
      "SET_APPEND_NEW_OLD_PRIVATE_MESSAGE_CONVERSTAION_LOADED",
      newOldMessagesLoaded.responseBody
    );
  },

  successFullSendPrivateMessage({ commit }, newMessage) {
    commit(
      "SET_SUCCESS_SEND_NEW_PRIVATE_MESSAGE",
      newMessage.newMessageToBeAppend
    );
  },
  disconnectSocketAction() {
    PrivateMessagesService.disconnect();
  },
};
