export default {
  // data
  privateConversations: [],
  privateConversationsHistory: [],
  actualOpenedConversationPersonUuid: null,
  unReadCountMessage: 0,
  // for data loading (data loaders)
  arePrivateConversationsLoading: false,
  areLoadMorePrivateMessage: false,
};
