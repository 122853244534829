import { ErrorWrapper, ResponseWrapper } from "./util";
import { Http } from "../axios";

export const LIVE_STREAM_STATUS = {
  notStarted: "PROGRAMMED",
  inProgress: "STARTED",
  terminated: "END",
};

export class LivePlanningService {
  /**
   ******************************
   * @API_CALLS
   ******************************
   **/

  static get entity() {
    return "livePlanning";
  }

  static request(status = { auth: true }) {
    return new Http(status);
  }

  static async createLive({ queryBody }) {
    try {
      const response = await this.request().post(`${this.entity}/`, queryBody);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data
        ? error.response.data.message
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async updateLive({ liveUuid, queryBody }) {
    try {
      const response = await this.request().put(
        `${this.entity}/${liveUuid}`,
        queryBody
      );
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data
        ? error.response.data.message
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async checkUserToken({ liveUuid, moduleUuid }) {
    try {
      const response = await this.request().get(
        `${this.entity}/live-access/${liveUuid}/${moduleUuid}`
      );
      return {
        response: new ResponseWrapper(response, response.data),
        success: true,
      };
    } catch (error) {
      const message = error.response.data
        ? error.response.data.message
        : error.statusText;
      return { success: false, message };
    }
  }

  static async getLiveById(id) {
    try {
      const response = await this.request().get(`${this.entity}/${id}`);
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = error.data ? error.data.message : error.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async checkLiveStatus({ liveUuid, moduleUuid, cb }) {
    const { response } = await this.checkUserToken({ liveUuid, moduleUuid });
    const livePlanning = response.data;
    console.log("checkLiveStatus:", livePlanning);
    console.log("checkLiveStatus-cb:", cb);
    if (livePlanning.state === LIVE_STREAM_STATUS.inProgress) {
      cb();
      return;
    }

    setTimeout(() => {
      console.log("Checking state again...");
      this.checkLiveStatus({ liveUuid, moduleUuid, cb });
    }, 5000);
  }

  static async updateLiveStatus(liveUuid, status) {
    return LivePlanningService.updateLive({ liveUuid, queryBody: { status } });
  }

  static async startLive(code, livePlanningUuid) {
    try {
      const response = await this.request().post(
        `${this.entity}/startLivePlanning`,
        {
          code,
          livePlanningUuid,
        }
      );
      return {
        response: new ResponseWrapper(response, response.data),
        success: true,
      };
    } catch (error) {
      console.log("error:", { error });
      const message = error.response.data
        ? error.response.data.message
        : error.statusText;
      return { success: false, message };
    }
  }

  static async endLive(code, livePlanningUuid) {
    try {
      const response = await this.request().post(
        `${this.entity}/stopLivePlanning`,
        {
          code,
          livePlanningUuid,
        }
      );
      return {
        response: new ResponseWrapper(response, response.data),
        success: true,
      };
    } catch (error) {
      const message = error.data ? error.data.message : error.statusText;
      return { success: false, message };
    }
  }

  static async getLiveByTeacherCode({ teacherCode }) {
    try {
      const response = await this.request().get(
        `${this.entity}/teacher/${teacherCode}`
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = error.data ? error.data.message : error.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getLiveByStudent({ studenProfileUuid }) {
    try {
      const response = await this.request().get(
        `${this.entity}/student/${studenProfileUuid}`
      );

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = error.response.data
        ? error.response.data.message
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async deleteLive({ liveUuid }) {
    try {
      const response = await this.request().delete(
        `${this.entity}/${liveUuid}`
      );
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data
        ? error.response.data.message
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
