export default {
  // data mutations
  SET_ALL_TEACHERS_PROFILES_LIST(state, value) {
    state.allTeachersProfilesList.push(...value);
  },

  SET_CURRENT_TEACHER_MODULES(state, value) {
    state.currentTeacherModules.push(...value);
  },

  SET_STUDENTS_PROFILES_LIST(state, value) {
    state.allStudentsProfilesList.push(...value);
  },

  SET_ADMINS_PROFILES_LIST(state, value) {
    state.allAdminsProfilesList.push(...value);
  },

  SET_ALL_MODULE_DELATION_REQUEST(state, value) {
    state.allModuleDelationRequest = value;
  },

  SET_TEACHER_PROFILES_TOTAL_COUNT(state, value) {
    state.teacherProfilesTotalCount = value;
  },
  SET_STUDENT_PROFILES_TOTAL_COUNT(state, value) {
    state.studentProfilesTotalCount = value;
  },

  SET_ADMIN_PROFILES_TOTAL_COUNT(state, value) {
    state.adminProfilesTotalCount = value;
  },

  SET_CURRENT_TEACHER_MODULES_TOTAL_COUNT(state, value) {
    state.currentTeacherModulesTotalCount = value;
  },
  SET_TEACHER_PROFILES_CURRENT_OFFSET(state, value) {
    state.teacherProfilesCurrentOffset = value;
  },
  SET_STUDENT_PROFILES_CURRENT_OFFSET(state, value) {
    state.studentProfilesCurrentOffset = value;
  },
  SET_ADMIN_PROFILES_CURRENT_OFFSET(state, value) {
    state.adminProfilesCurrentOffset = value;
  },
  SET_CURRENT_TEACHER_MODULES_OFFSET(state, value) {
    state.currentTeacherModulesOffset = value;
  },

  // data's loaders mutations
  SET_ARE_ALL_TEACHERS_PROFILES_LIST_LOADING(state, value) {
    state.areAllTeachersProfilesListLoading = value;
  },

  SET_ARE_STUDENTS_PROFILES_LIST_LOADING(state, value) {
    state.areAllStudentsProfilesListLoading = value;
  },

  SET_ARE_ADMINS_PROFILES_LIST_LOADING(state, value) {
    state.areAllAdminsProfilesListLoading = value;
  },
  SET_ARE_CURRENT_TEACHER_MODULES_LOADING(state, value) {
    state.areCurrentTeacherModuleLoading = value;
  },
  SET_ARE_REGISTERED_STUDENTS_LOADING(state, value) {
    state.areRegisteredStudentsLoading = value;
  },
};
