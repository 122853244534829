export default {
  // data mutations
  SET_ALL_LEARNING_MODULE_LIST(state, value) {
    state.allLearningModulesList = value;
  },

  SET_TEACHER_MODULES_RETRIEVED_BY_TEACHER_CODE(state, value) {
    state.teacherModulesRetrievedByTeacherCode = value;
  },

  SET_CONNECTED_TEACHER_MODULES(state, value) {
    state.connectedTeacherModules = value;
  },

  SET_CONNECTED_STUDENT_MODULES(state, value) {
    state.connectedStudentModules = value;
  },

  SET_STUDENTS_BY_LEARNING_MODULE(state, val) {
    state.studentsByLearningModule = val;
  },

  // data's loaders mutations
  SET_ARE_ALL_LEARNING_MODULE_LIST_LOADING(state, value) {
    state.areAllLearningModulesListLoading = value;
  },

  SET_ARE_TEACHER_MODULES_RETRIEVED_BY_TEACHER_CODE_LOADING(state, value) {
    state.areTeacherModulesRetrievedByTeacherCodeLoading = value;
  },

  SET_ARE_CONNECTED_TEACHER_MODULES_LOADING(state, value) {
    state.areConnectedTeacherModulesLoading = value;
  },

  SET_ARE_CONNECTED_STUDENT_MODULES_LOADING(state, value) {
    state.areConnectedStudentModulesLoading = value;
  },

  SET_ARE_SENDING_LEARNING_MODULE_REGISTRATION_LOADING(state, value) {
    state.areSendingLearningModuleRegistrationLoading = value;
  },
};
