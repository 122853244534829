import auth from "./auth";
import user from "./user";
import learning from "./learning";
import learningModuleForum from "./learningModuleForum";
import privateMessaging from "./privateMessaging";
import livePlanning from "./live-planning";
import video from "./video";
import admin from "./admin";
import dom from "./dom";
import toast from "./toast";

export default {
  auth,
  user,
  learning,
  learningModuleForum,
  privateMessaging,
  livePlanning,
  video,
  admin,
  dom,
  toast,
};
