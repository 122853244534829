/* eslint-disable no-unused-vars */
import { ErrorWrapper, ResponseWrapper } from "./util";
import { Http } from "../axios";

export class UsersService {
  static get entity() {
    return "module-user";
  }

  static async getCurrent() {
    try {
      const response = localStorage.getItem("currentUser");
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(response);
        }, 100);
      });
      //return response
    } catch (error) {
      const message = error;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getStudentsByLearningModule({ queryBody }) {
    try {
      const response = await http.post(`${this.entity}/all`, queryBody);
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = error.data ? error.data.message : error.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
