export default {
  // data
  teacherLivesRetrievedByTeacherCode: [],
  connectedStudentLivesSubribed: [],

  // for data loading (data loaders)

  areTeacherLivesRetrievedByTeacherCodeLoading: false,
  areConnectedStudentLivesSubribedLoading: true,
};
