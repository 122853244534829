import { app } from "@/main";

export default {
  // data mutations
  SET_PRIVATE_CONVERSATIONS(state, value) {
    state.privateConversations = value;
  },

  SET_PRIVATE_CONVERSATIONS_HISTORY(state, value) {
    state.privateConversationsHistory = value;
  },

  SET_UPDATE_SPECIQ_PERSON_IN_PRIVATE_CONVERSATIONS_HISTORY_LAST_MESSAGE(
    state,
    value
  ) {
    let i = state.privateConversationsHistory.findIndex((x) => {
      if (app.$currentUser.teacherStatus === false) {
        if (app.$currentUser.uuid === value.SenderUuid)
          return x.Module?.Creator?.uuid === value.ReceiverUuid;
        return x.Module?.Creator?.uuid === value.SenderUuid;
      } else {
        if (app.$currentUser.uuid === value.SenderUuid)
          return x.Profile.uuid === value.ReceiverUuid;
        return x.Profile.uuid === value.SenderUuid;
      }
    });

    if (i >= 0) {
      let targetPerson = state.privateConversationsHistory[i];
      targetPerson.lastMessage = value.content;
      targetPerson.lastMessageDateTime = value.createdAt;
      state.privateConversationsHistory[i] = targetPerson;
      state.privateConversationsHistory =
        state.privateConversationsHistory.sort(
          (objA, objB) =>
            new Date(objB?.lastMessageDateTime) -
            new Date(objA.lastMessageDateTime)
        );
    }
  },

  SET_APPEND_NEW_PRIVATE_MESSAGE(state, value) {
    state.privateConversations.push(value);
  },

  SET_SUCCESS_SEND_NEW_PRIVATE_MESSAGE(state, value) {
    const msgIndex = state.privateConversations.findIndex(
      (el) => el.uuid === value.uuid
    );
    if (msgIndex >= 0) {
      let targetMsg = state.privateConversations[msgIndex];
      targetMsg.ongoing = false;
      state.privateConversations[msgIndex] = targetMsg;
    }
  },

  SET_APPEND_NEW_INCOMING_PRIVATE_MESSAGE(state, value) {
    if (value.SenderUuid === state.actualOpenedConversationPersonUuid) {
      state.privateConversations.push(value);
    }
  },

  SET_APPEND_NEW_OLD_PRIVATE_MESSAGE_CONVERSTAION_LOADED(state, value) {
    if (
      value.length === 1 &&
      state.privateConversations.findIndex((el) => el.uuid == value[0].uuid) < 0
    ) {
      let contactedConversation = value.concat(state.privateConversations);
      state.privateConversations = contactedConversation.sort(
        (objA, objB) => new Date(objA.createdAt) - new Date(objB.createdAt)
      );
    }
    if (value.length > 1) {
      let contactedConversation = value.concat(state.privateConversations);
      state.privateConversations = contactedConversation.sort(
        (objA, objB) => new Date(objA.createdAt) - new Date(objB.createdAt)
      );
    }
  },

  SET_UNREAD_COUNT_MESSAGE(state, value) {
    state.unReadCountMessage = value;
  },

  SET_ACTUAL_OPENED_CONVERSION_PERSON_UUID(state, value) {
    state.actualOpenedConversationPersonUuid = value;
  },

  // data's loaders mutations
  SET_ARE_PRIVATE_CONVERSATIONS_LOADING(state, value) {
    state.privateConversationsLoading = value;
  },

  // data's loaders mutations
  SET_ARE_APPEND_NEW_OLD_PRIVATE_MESSAGE_CONVERSTATION_LOADED_LOADING(
    state,
    value
  ) {
    state.areLoadMorePrivateMessage = value;
  },
};
