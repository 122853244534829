import state from "./adminState";
import mutations from "./adminMutations";
import getters from "./adminGetters";
import actions from "./adminActions";

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
