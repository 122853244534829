/**
 * toast notification module
 */
export default {
  namespaced: true,
  state: {
    toastsList: [],
  },

  mutations: {
    NEW(state, payload) {
      // console.log(payload);
      payload.title = payload.title || "Notification";
      payload.position = payload.position || "topCenter";
      const toast = {
        id: new Date().getTime(),
        message: typeof payload === "string" ? payload : payload.message,
        type: payload.type || "default",
        duration: payload.duration || 5000,
      };

      switch (payload.type) {
        case "error":
          toast.duration = 0;
          payload.vm.error(`${payload.message}`, payload.title, {
            position: payload.position,
            layout: 2,
            messageLineHeight: 20,
          });
          break;

        case "success":
          payload.vm.success(`${payload.message}`, payload.title, {
            position: payload.position,
            layout: 2,
            messageLineHeight: 20,
          });
          break;

        case "info":
          //payload.vm.
          payload.vm.info(`${payload.message}`, "Notification", {
            position: payload.position,
            layout: 2,
            messageLineHeight: 20,
          });
          break;

        case "upload-video":
          payload.vm.question(`${payload.message}`, payload.title, {
            position: payload.position,
            layout: 1,
            messageLineHeight: 20,
            icon: "thumbs-up",
            timeout: false,
            close: true,
            overlay: false,
            toastOnce: true,
            id: "question",
            zindex: 999,
            buttons: [
              [
                `<button class="btn btn-dark-blue btn-lg p-2 m-4"><b>${payload.acceptBtn}</b></button>`,
                function (instance, toast) {
                  instance.hide({ transitionOut: "fadeOut" }, toast, "yes");
                },
                true,
              ],
            ],
            onClosing: function (instance, toast, closedBy) {
              console.info("Closing | closedBy: " + closedBy);
              return closedBy;
            },
            onClosed: function (instance, toast, closedBy) {
              console.info("Closed | closedBy: " + closedBy);
              return closedBy;
            },
          });
          break;

        case "ask-deletion":
          payload.vm.question(`${payload.message}`, "Confirmation", {
            position: "center",
            layout: 2,
            messageLineHeight: 20,
            timeout: false,
            close: false,
            overlay: true,
            toastOnce: true,
            id: "question",
            zindex: 999,
            buttons: [
              [
                `<button><b>${payload.acceptBtn}</b></button>`,
                function (instance, toast) {
                  instance.hide({ transitionOut: "fadeOut" }, toast, "yes");
                },
                true,
              ],
              [
                `<button><b>${payload.rejectBtn}</b></button>`,
                function (instance, toast) {
                  instance.hide({ transitionOut: "fadeOut" }, toast, "no");
                },
              ],
            ],
            onClosing: function (instance, toast, closedBy) {
              console.info("Closing | closedBy: " + closedBy);
              console.info(instance);
              console.info(toast);
              return closedBy;
            },
            onClosed: function (instance, toast, closedBy) {
              console.info("Closed | closedBy: " + closedBy);
              console.info(instance);
              console.info(toast);
              return closedBy;
            },
          });
          break;

        default:
          break;
      }

      state.toastsList.push({ ...toast });
    },

    REMOVE(state, payload) {
      state.toastsList = state.toastsList.filter(({ id }) => id !== payload.id);
    },

    CLEAR_LIST(state) {
      state.toastsList = [];
    },
  },
};
