export default {
  // data mutations

  SET_FORUM_CONVERSATIONS(state, value) {
    state.forumConversations = value;
  },

  SET_APPEND_NEW_MESSAGE_FORUM_CONVERSATIONS(state, value) {
    state.forumConversations.push(value);
  },

  SET_APPEND_NEW_OLD_MESSAGE_LOADED_FORUM_CONVERSATIONS(state, value) {
    //state.forumConversations = value.concat(state.forumConversations);

    if (
      value.length === 1 &&
      state.forumConversations.findIndex((el) => el.uuid == value[0].uuid) < 0
    ) {
      let contactedConversation = value.concat(state.forumConversations);
      state.forumConversations = contactedConversation.sort(
        (objA, objB) => new Date(objA.createdAt) - new Date(objB.createdAt)
      );
    }
    if (value.length > 1) {
      let contactedConversation = value.concat(state.forumConversations);
      state.forumConversations = contactedConversation.sort(
        (objA, objB) => new Date(objA.createdAt) - new Date(objB.createdAt)
      );
    }
  },

  SET_SUCCESS_SEND_NEW_MESSAGE(state, value) {
    const msgIndex = state.forumConversations.findIndex(
      (el) => el.uuid === value.uuid
    );
    if (msgIndex >= 0) {
      let targetMsg = state.forumConversations[msgIndex];
      targetMsg.ongoing = false;
      state.forumConversations[msgIndex] = targetMsg;
    }
  },

  // data's loaders mutations
  SET_ARE_FORUM_CONVERSATIONS_LOADING(state, value) {
    state.forumConversationsLoading = value;
  },

  // data's loaders mutations
  SET_ARE_APPEND_NEW_OLD_MESSAGE_LOADED_FORUM_CONVERSATIONS_LOADING(
    state,
    value
  ) {
    state.areLoadMoreMessage = value;
  },
};
