import state from "./privateMessagingModuleState";
import mutations from "./privateMessagingModuleMutations";
import getters from "./privateMessagingModuleGetters";
import actions from "./privateMessagingModuleActions";

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
