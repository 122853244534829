export default {
  methods: {
    formattedDate(originalDate) {
      if (originalDate !== undefined) {
        const publishedAt = new Date(originalDate);
        return publishedAt.toLocaleString("fr-FR", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          timeZoneName: "short",
        });
      }
    },
  },
};
