export default {
  // data
  getTeacherLivesRetrievedByTeacherCode: (state) =>
    state.teacherLivesRetrievedByTeacherCode,
  getConnectedStudentLivesSubribed: (state) =>
    state.connectedStudentLivesSubribed,

  // data's loaders state getters
  areTeacherLivesRetrievedByTeacherCode: (state) =>
    state.areTeacherLivesRetrievedByTeacherCode,
  areConnectedStudentLivesSubribed: (state) =>
    state.areConnectedStudentLivesSubribed,
};
