export default {
  // data
  allVideoModulesList: [],
  streamVideoModule: {},
  otts: [],

  // for data loading (data loaders)
  areAllVideoModulesListLoading: true,
  areStreamVideoModuleLoading: true,
};
