import state from "./videoModuleState";
import mutations from "./videoModuleMutations";
import getters from "./videoModuleGetters";
import actions from "./videoModuleActions";

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
