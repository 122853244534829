/**
 * import and init global mixins
 */

import Vue from "vue";

import currentUser from "../mixins/currentUser";
import confirmationBox from "../mixins/confirmationBox";
import currentDateTime from "@/mixins/currentDateTime";
import getFileExtension from "@/mixins/getFileExtension";
import formattedDate from "./formatDate";

Vue.mixin(currentUser);
Vue.mixin(confirmationBox);
Vue.mixin(currentDateTime);
Vue.mixin(getFileExtension);
Vue.mixin(formattedDate);
