export default {
  currentUser: {
    uuid: "",
    role: "",
    code: "",
    email: "",
    connected: "",
    teacherStatus: false,
    status: "",
    createdAt: "",
    updatedAt: "",
    deletedAt: null,
    ProfileIdentity: {
      uuid: "",
      firstname: "",
      lastname: "",
      establishment: "",
      faculty: "",
      level: "",
      phone: "",
      createdAt: "",
      updatedAt: "",
      deletedAt: null,
      ProfileUuid: "",
    },
  },
};
