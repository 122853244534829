export default {
  // data
  getAllVideoModulesList: (state) => state.allVideoModulesList,
  getOttList: (state) => state.otts,
  getStreamVideoModule: (state) => state.streamVideoModule,

  // data's loaders state getters
  areAllVideoModulesListLoading: (state) => state.areAllVideoModulesListLoading,
  areStreamVideoModuleLoading: (state) => state.areStreamVideoModuleLoading,
};
